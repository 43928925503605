.device-control {
  .MuiAccordion-root {
    box-shadow: 10px 2px 1px -1px transparent, 0px 1px 1px 0px rgba(255, 255, 255, 0), 0px 1px 3px 0px transparent !important;
    border-radius: 10px !important;
  }
  .MuiAccordionDetails-root {
    border-top: 2px solid #e5e7eb;
  }
  .MuiAccordionSummary-content {
    margin: 0 !important;
    justify-content: space-between !important;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto !important;
    padding: 0.5rem 16px 0 16px;
  }
  .info-card-dev {
    min-width: 524px;
    width: 100%;

    @media (max-width: 1441px) {
      min-width: 400px;
    }
    .info-header-cls span:nth-of-type(1) {
      font-size: 23px;
      font-weight: 500;
      line-height: 23px;
      margin-top: 5px;
      color: #3d4854;
    }
    .info-div {
      padding: 13px 10px 9px 20px;
      .info-name-cls {
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        color: #5d6670;
        width: 30%;
        @media (max-width: 1441px) {
          font-size: 14px;
          width: 30%;
        }
      }
      .info-desc-cls {
        font-size: 15px;
        line-height: 15.6px;
        font-weight: 400;
        color: #5d6670;
        width: 70%;
      }
      .info-logo-cls {
        margin-top: -6px;
      }
      .info-desc-div {
        display: contents;
      }

      &:active {
        background-color: #e6f1fe;
      }
      .customer-service-cls {
        width: 69px;
        // height: 28px;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 400;
        line-height: 15.6px;
        text-align: center;
        margin-right: 12px;
        text-transform: capitalize;
        margin-bottom: 6px;
        border: 1px solid #d9d9d9;
        color: #5d6670 !important;
      }
      .active {
        border: 1px solid #0da300 !important;
      }

      .progressbar-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 19.09px;
        color: #ff8181;
      }
    }
    .block {
      // height: 60px;
      border-radius: 10px;
      background: #ffff;
      padding: 18px 20px;
      display: grid;
      grid-template-columns: 100px 1fr 100px;
      .icon-cls {
        background: #e7e8ea;
        // height: 34px;
        width: 34px;
        border-radius: 4px;
        padding: 7px;
      }
    }
    .active-activity {
      background: #e6f1fe;
    }
  }
  .info-card-dev {
    border-radius: 10px !important;
    box-shadow: 0px 2px 1px -1px transparent, 0px 1px 1px 0px #ffffff00, 0px 1px 3px 0px transparent !important;
  }
  @media (max-width: 1767px) {
    .info-card-dev {
      min-width: 480px;
    }
  }
  @media (max-width: 1367px) {
    .info-card-dev {
      min-width: 430px;
    }
  }
}
