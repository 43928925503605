.Accordion-header {
  //   position: relative;
  //   display: flex;
  //   .title {
  //     font-style: normal;
  //     font-weight: 700;
  //     font-size: 18px;
  //     line-height: 21px;
  //     color: #282828;
  //     // font-family: $primary-font-family;
  //     margin-bottom: 23px;
  //     &:first-child {
  //       // position: absolute;
  //       // left: 10px;
  //       margin-left: 32px;
  //     }
  //     &:nth-child(2) {
  //       position: absolute;
  //       left: 458px;
  //     }
  //     &:nth-child(3) {
  //       position: absolute;
  //       left: 700px;
  //     }
  //     &:nth-child(4) {
  //       position: absolute;
  //       left: 950px;
  //     }
  //     &:nth-child(5) {
  //       position: absolute;
  //       left: 1250px;
  //     }
  //   }
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #282828;
    margin-bottom: 10px !important;
  }
}

.TreeView-header {
  position: relative;
  display: flex;
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 23px;
    color: #282828;
    // font-family: $primary-font-family;
    &:first-child {
      margin-left: 5px;
    }
    &:nth-child(2) {
      position: absolute;
      left: 455px;
    }
    &:nth-child(3) {
      position: absolute;
      left: 700px;
    }
    &:nth-child(4) {
      position: absolute;
      left: 950px;
    }
    &:nth-child(5) {
      position: absolute;
      left: 1250px;
    }
  }
}
.TreeView {
  .Treeview_header {
    height: 60px;
    border-radius: 10px;
    .MuiTreeItem-content {
      padding: 0 !important;
    }
    .Accordion-title {
      margin-left: 30px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #282828;
      //   font-family: $primary-font-family !important;
    }
  }
  .MuiTreeItem-content {
    background-color: #f9f9f9 !important;
    height: 60px;
    border-radius: 10px;
    border: 2px solid transparent;
    &:hover {
      border: 2px solid #cbcfde;
    }
    .MuiTreeItem-label {
      padding: 0 !important;
    }
    .MuiTreeItem-iconContainer {
      display: none !important;
    }
  }
  .Treeview-item {
    margin-top: 16px !important;
    max-width: 1385px !important;
    .MuiTreeItem-content {
      margin-left: 20px !important;
    }
    &::before {
      pointer-events: none;
      content: '';
      position: absolute;
      width: 32px;
      left: -16px;
      top: 30px;
      border-bottom: 1px dashed gray;
    }
  }
  .MuiCollapse-entered {
    .MuiCollapse-wrapper {
      .Treeview-item {
        max-width: 1385px !important;
      }
    }
  }
  .TreeView_child_container {
    display: flex;
    align-items: center;
    position: relative;
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #282828;
      //   font-family: $primary-font-family;
      margin-left: 40px;
    }
    .category-title {
      width: 98px;
      position: absolute;
      left: 400px;
      //   font-family: $primary-font-family;
      max-width: 98px;
      height: 30px;
      border-radius: 5px;
      border: 2px solid #ff8181;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      text-align: center;
      color: #282828;
    }
    .affected-device {
      position: absolute;
      left: 640px;
      //   font-family: $primary-font-family;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #282828;
    }
    .time {
      position: absolute;
      left: 887px;
      font-style: normal;
      font-weight: 400;
      //   font-family: $primary-font-family;
      font-size: 16px;
      line-height: 19px;
      color: #282828;

      span {
        margin-left: 5px;
      }
    }
    .action {
      svg {
        position: absolute;
        left: 1195px;
      }
      a {
        position: absolute;
        left: 1228px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        // font-family: $primary-font-family;
        line-height: 19px;
        text-decoration-line: underline;
        color: #6c83ff;
      }
    }
  }
}


.width-20 {
  width: 20%;
  // min-width: 400px;
}
.width-21 {
  width: 20.6%;
  // min-width: 400px;
}
.width-21-5 {
  width: 21.5%;
  // min-width: 400px;
}
.width-28-3 {
  width: 28.3%;
  // min-width: 400px;
}
.width-28 {
  width: 28%;
  // min-width: 400px;
}
.width-29 {
  width: 26%;
  // min-width: 400px;
}
.width-30 {
  width: 30%;
  // min-width: 400px;
}

.width-33 {
  width: 33%;
  // min-width: 400px;
}
