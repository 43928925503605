.activity-section {
  .container {
    height: 450px;
    overflow: scroll;
  }
  .active-iot-activity-details-cls {
    padding-top: 13px;
    background: #ffffff;
  }
  .activities-label-cls-iot {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 98%;
  }
  .activity-logo-cls {
    padding: 3px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
