.ant-picker-ok {
  button {
    background: #1677ff !important;
  }
}
.telemetry-chart {
  .submit-chart-btn {
    background-color: #4caf50;
  }
}
