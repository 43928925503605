.rightSideBar-wrapper {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  .reload-btn {
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    border: 2px solid #eff2fc;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    cursor: pointer;
    &:hover {
      background: #eff2fc;
    }
  }
}
