.custom_main_wrapper {
    background-color: #ffffff;
    border: 1px solid #eff2fc;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9;
    .customer_header_wrapper {
        // height: 81px;
        padding: 19px 20px 0px;
        // @media (max-width: 1400px) {
        //     max-width: 1300px;
        // }
        .MuiTab-root {
            width: fit-content !important;
        }
    }
    .custome-padding {
        padding: 19px 20px 20px;
    }
}

.active-bg {
    background-color: rgba(230, 241, 254, 1);
}

.selectInputClass .ant-select-selector {
    border-radius: 0.7rem;
    padding: 0px 15px !important;
}
.selectInputClassError .ant-select-selector {
    border-color: red !important;
}

.custom-select .ant-select-selector {
    max-height: 100px !important; /* Fixed height */
    overflow-y: auto !important; /* Enable scrolling */
    display: flex;
    flex-wrap: wrap;
}

.sidebarDrawer .ant-drawer-content-wrapper {
    width: 1440px !important;
}
.EditBatchDrawer .ant-drawer-content-wrapper {
    width: 780px !important;
}
.userSidebarDrawer .ant-drawer-content-wrapper {
    width: 1080px !important;
}
.multiSelectClass .ant-select-selector .ant-select-selection-overflow {
    height: 4.7vh;
}
