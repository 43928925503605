@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  font-family: "Noto Sans", sans-serif;
  font-family: "Roboto", sans-serif;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  :root { font-family: 'Inter var', sans-serif; }
}

h1, h2, h3, h4, h5, h6{
  font-weight: 500 !important;
}

p{
  margin: 0 !important;
}

.web-supervision-inner-wrapper::-webkit-scrollbar-track,
.deviceList-main-wrapper::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.web-supervision-inner-wrapper::-webkit-scrollbar,
.deviceList-main-wrapper::-webkit-scrollbar
{
	height: 5px;
	background-color: #F5F5F5;
}

.web-supervision-inner-wrapper::-webkit-scrollbar-thumb,
.deviceList-main-wrapper::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #a7a7a7;
}

.ant-switch{
  background-color: rgba(0, 0, 0, 0.25);
}
.loading-local-wrapper{
  height: 100px !important;
}