.deviceList-main-wrapper {
  display: flex;
  column-gap: 30px;
  overflow-x: auto;
  flex-direction: row;
  margin-bottom: 28px;
  padding-bottom: 12px;
}

.hidden {
  display: none;
}
