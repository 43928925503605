.supervision-wrapper {
  .supervision-inner-block {
    .inner-block-title {
      font-size: 24px;
      padding: 30px;
      background-color: #fff;
      font-family: SFProDisplay-SemiBold, sans-serif !important;
      color: rgb(40 40 40);
      border-radius: 10px;
    }
    .inner-block-tab-main {
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      gap: 20px 30px;
      width: 100%;
      padding: 0 36px;
      margin-bottom: 12px;
      .pc-category-button-active {
        border-radius: 40px;
        border: 1px solid #eff2fc;
     
        box-shadow: 0px 2px 15px 0px rgba(108, 131, 255, 0.63);
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        padding: 14px 25px;
        @apply font-sf-bold;
      }

      .pc-category-button-inactive {
        border-radius: 40px;
        border: 1px solid #eff2fc;
      

        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        padding: 14px 25px;
        @apply font-sf-bold;
      }

      .usefont {
        font-family: SFProDisplay-SemiBold, sans-serif !important;
        cursor: pointer;
      }
    }
    .table-main-wrapper {
      border-radius: 10px;
    }
  }
}
