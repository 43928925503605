.statistic-wrapper {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin: 24px 0;
  box-shadow: 0px 12px 25px -14px rgba(255, 255, 255, 0.75);
  -webkit-box-shadow: 0px 12px 25px -14px rgba(255, 255, 255, 0.75);
  -moz-box-shadow: 0px 12px 25px -14px rgba(255, 255, 255, 0.75);
  .title {
    font-size: 18px;
    margin: 10px 0 30px 0;
  }
}
