.verifypin-wrapper {
  margin-top: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  .verifypin-wrapper-main {
    padding: 16px !important;
    border-radius: 6px;
    min-width: 389px;
    background-color: #fff !important;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
    .verifypin-form-inner {
      padding: 12px 0 !important;
      h1 {
        font-size: inherit;
        font-weight: inherit !important;
        margin: 0;
        font-family: SFProDisplay-Medium, sans-serif !important;
      }
      .verifypin-form-input {
        padding: 12px !important;
        border-radius: 10px;
        width: 100%;
        border: 1px solid #687a9d;
        margin-top: 16px !important;
      }
      .errorText {
        font-size: 14px;
        color: #ff8181;
      }
    }
    .verify-btn {
      width: 100%;
      margin: 0 auto;
      .button {
        padding: 8px 16px;
        border-radius: 10px;
        // background-color: #a3d696;
        color: #fff;
        width: 100%;
        border: none;
        cursor: pointer;
        &:hover {
          box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
        }
      }
      .button:disabled,
      .button[disabled] {
        // border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
      }
    }
  }
}
