.alltile-main {
  background-color: #fff;
  position: relative;
  border-radius: 10px;
  padding: 13px 39px;
  text-align: center;
  max-width: 153px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 11px 0;
  border: 2px solid #e5e7eb;
  &.activeTile {
    // border-color: #ff8284;
  }
  .img-block {
    width: 90px;
    height: 70px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .alltile-inner {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 110px;
    width: 100%;
    font-size: 14px;
    color: rgb(40 40 40);
    font-family: SFProDisplay-Regular, sans-serif !important;
    &.activeTileText {
      color: rgb(255, 129, 129);
    }
  }
  .bottomBlock {
    position: absolute;
    top: 4px;
    left: 4px;
  }
  .bottomBlockRight {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}

.text-alerts-info {
  color: #6c83ff;
}

.text-alerts-critical {
  color: rgb(255, 129, 129);
}
.text-alerts-good {
  color: #01be4f;
}
