.dashboard-main-wrapper{
    .dashboard-inner-wrapper{
        display: flex;
        gap: 24px;
        width: 100%;
        padding-top: 66px;
        flex-wrap: wrap;
        justify-content: center;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        .dashboard-main-block{
            display: flex;
            flex-direction: column;
            min-width: 819px;
            width: 100%;
            gap: 41px 0;
        }
        .dashboard-social-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            gap: 30px;
            
            .dashboard-social{
                // max-width: 820px;
                width: 50%;
                @media (max-width: 1500px) {
                    // max-width: 420px;
                }
                .highcharts-container {
                    border-radius: 10px;
                }
            }
            .dashboard-other-block{
                width: 50%;
                .highcharts-container {
                    border-radius: 10px;
                }
            }
        }
        
    }
}