.pagination-wrapper {
  width: 100%;
  min-width: 250px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 22px;
  user-select: none;
  .left-side {
    align-items: center;
    display: flex;
    // height: 40px;
    // .left-side-text {
    p {
      color: #90969d;
      font-size: 13px;
      line-height: 1;
      margin: 0;
    }
    // }
  }
  .right-side {
    align-items: center;
    display: flex;
    .left-arrow,
    .right-arrow {
      padding: 5px;
    }
    .page-count {
      display: flex;

      .active-page {
        padding: 5px 10px;
        background-color: #e7e8ea;
        border-radius: 4px;
        border: none;
        user-select: none;
      }
      .disable-page {
        padding: 5px 10px;
        color: #90969d;
        user-select: none;
      }
    }
  }
}
