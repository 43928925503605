.layout-wrapper {
  display: flex;
  overflow: hidden;
  .layout-wrapper-inner {
    flex-grow: 1;
    // height: calc(100vh - 241px) !important;
    overflow-x: auto;
    // overflow-y: hidden;
    .layout-inner-main {
      display: flex;
      .layout-inner-block {
        width: 100%;
        overflow-x: auto;
        .page-content {
          // height: calc(100vh - 215px);
          overflow-y: auto;
          // padding-bottom: 50px;
          scrollbar-width: none;

          .pc-table {
            border-radius: 10px;
            background-color: #fff;
          }
        }
      }
      .layout-inner-mainblock {
        width: 62px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 34px;
        gap: 6px 0;
      }
    }
  }
}
