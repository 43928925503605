.TooltipContent {
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 15px;
    line-height: 1;
    color: var(--violet-11);
    background-color: white;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    user-select: none;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
  }
  .TooltipContent[data-state='delayed-open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }
  .TooltipContent[data-state='delayed-open'][data-side='right'] {
    animation-name: slideLeftAndFade;
  }
  .TooltipContent[data-state='delayed-open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }
  .TooltipContent[data-state='delayed-open'][data-side='left'] {
    animation-name: slideRightAndFade;
  }
  
  .TooltipArrow {
    fill: white;
    stroke: #ff8181;
    stroke-width: 2px;
    width: 8px;
    height: 8px;
    position: absolute;
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .IconButton {
    font-family: inherit;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    border: 2px solid #ff8181;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--violet-11);
    background-color: white;
    box-shadow: 0 2px 10px var(--black-a7);
  }
  .IconButton:hover {
    background-color: var(--violet-3);
  }
  .IconButton:focus {
    box-shadow: 0 0 0 2px black;
  }
  
  .btn-popover {
    border: 2px solid transparent;
  }
  .btn-span {
    border: 2px solid #ff8181;
  }
  .btn-popover:hover {
    border: 2px solid #eff2fc;
  }
  