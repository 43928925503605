.speedTestWrapper {
  .deviceListWrapper {
    display: flex;
    .deviceWrapper {
      display: flex;
      flex-direction: column;
      margin-right: 16px;
      // .active-border {
      //   box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
      //   // border: 2px solid #a3d696;
      //   border: 2px solid rgb(255 129 129 / 1);
      // }
      // .hitron-active-border {
      //   box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
      //   // border: 2px solid #a3d696;
      //   border: 2px solid #dc4405;
      // }
      // .active-text {
      //   color: rgb(255 129 129);
      // }
      // .hitron-active-text {
      //   color: #dc4405;
      // }
      .in-active-border {
        // border: 2px solid #a3d69666;
        border: 2px solid #e3e3e3;
      }
      // .satelite-icon {
      //   background-color: #1976d2;
      // }
      // .master-icon {
      //   background-color: #a3d696;
      // }
      // .hitron-master-icon {
      //   background-color: #00cc66;
      // }
      .device {
        &-box {
          display: flex;
          flex-direction: column;
          margin: 5px;
          border-radius: 10px;
          align-items: center;
          padding: 5px 10px;
          position: relative;
          width: 170px;
          overflow: hidden;
        }
        &_title {
          padding-top: 5px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          cursor: default;
        }
        &_type {
          position: absolute;
          // background: #a3d696;
          border-radius: 50%;
          font-size: 12px;
          top: 6px;
          right: 6px;
        }
        &_image {
          width: 110px;
          // max-height: 90px;
        }
      }
    }
    .device_picker {
      border-radius: 5px;
      border: none;
      outline: none;
      margin: 10px;
      padding: 15px;
      color: #ffffff;
    }
    .disable {
      background: #ff8181;
    }
    .disable-btn {
      background: #c5ddbf;
    }
  }
  .SpeedTestWrapper {
    padding: 25px 0;
    .hitron-active-filter {
      background: #004c97 !important;
      box-shadow:
        1.5px 1.5px 3px 0 rgba(174, 174, 192, 0.4),
        -1px -1px 3px 0 #fff;
      color: #fff !important;
      padding: 8px 16px;
      border-radius: 10px;
    }
    .customer-active-filter {
      background: #ff974c !important;
      box-shadow:
        1.5px 1.5px 3px 0 rgba(174, 174, 192, 0.4),
        -1px -1px 3px 0 #fff;
      color: #fff !important;
      padding: 8px 16px;
      border-radius: 10px;
    }
    .active-filter {
      background: #a3d696 !important;
      box-shadow:
        1.5px 1.5px 3px 0 rgba(174, 174, 192, 0.4),
        -1px -1px 3px 0 #fff;
      color: #fff !important;
      padding: 8px 16px;
      border-radius: 10px;
    }
    .entpnt-active-filter {
      background: #487a7b !important;
      box-shadow:
        1.5px 1.5px 3px 0 rgba(174, 174, 192, 0.4),
        -1px -1px 3px 0 #fff;
      color: #fff !important;
      padding: 8px 16px;
      border-radius: 10px;
    }
    .active-filter,
    .inactive-filter {
      background: #ffffff;
      border-radius: 10px;
      font-size: 14px;
      margin: 0 8px;
      padding: 8px 16px;
    }
    .bg-layout-neutralBg {
      background: #ffffff;
      padding: 30px;
      margin: 12px 0;
      border-radius: 8px;
      h1 {
        font-size: 18px;
        margin-bottom: 1.5rem;
      }
    }
  }
  .speedTestChartDescription {
    display: flex;
    justify-content: space-evenly;
    padding-top: 30px;
  }
}

.highcharts-credits {
  display: none !important;
}
