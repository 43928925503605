.policy-main-wrapper{
    .policy-main-block{
        margin-bottom: 12px;
        .policy-block-empty{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 20px;
            .policy-block-text{
                font-size: 24px;
                font-family: SFProDisplay-SemiBold, sans-serif;
            }
        }
    }
}