.breadcrumbs{
    display: flex;
    align-items: center;
    background-color: #ffffff;
    &-line{
        height: 64px ;
        width: 1px;
        background-color: #cccccc;
    }
    &-options{
        display: flex;
        margin-left: 14px !important;
    }
    &-option{
        margin: 0 20px !important;
        cursor: pointer;
        p{
            font-weight: 500;
        }
    }
}