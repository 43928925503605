.cls-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #0070f0 !important;
}
div:focus-visible {
  outline: none !important;
}

.customer-tab {
  width: 151px;
}
.customer-tab-health {
  width: 172px;
}
.customer-tab-control {
  width: 180px;
}
.tab-label {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-transform: capitalize;
  margin-left: 10px;
  margin-right: 10px;
}
.cls-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: #90969d !important;
}
.tab-div {
  align-items: center;
  margin-bottom: 5px;
  width: fit-content !important;
}

// New Css Start
.overview-tab,
.info-card-cls,
.activity-card {
  border-radius: 10px !important;
  box-shadow:
    0px 2px 1px -1px transparent,
    0px 1px 1px 0px #ffffff00,
    0px 1px 3px 0px transparent !important;
}
.overview-tab-cls {
  min-width: 254px;
  width: 13.95%;
  .overview-tab span:nth-of-type(1) {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #5d6670;
    @media (max-width: 1441px) {
      font-size: 14px;
    }
  }
  .overview-tab span:nth-of-type(2) {
    font-size: 13px;
    font-weight: 400;
    line-height: 15.6px;
    color: #5d6670;
    padding-top: 5px;
  }
  .qoe-score-box {
    width: 100%;
    min-width: 254px;
    height: 180px;
    .qoe-action-box > div:nth-child(3) {
      margin-top: 25px !important;
      margin-left: 10px;
    }
  }
  .all-other-box {
    width: 100%;
    min-width: 254px;
    height: 84px;
    .status-box {
      width: 100%;
    }
    .status-box > div:nth-child(3) {
      align-self: auto !important;
    }
  }
  .alert-box {
    width: 100%;
    min-width: 254px;
    height: 362px;
    .status-box {
      width: 100%;
    }
    .status-box > div:nth-child(3) {
      align-self: auto !important;
    }
    .charttext .apexcharts-datalabel-value {
      font-weight: 700 !important;
      font-size: 23px !important;
      line-height: 27.26px !important;
      color: #464646 !important;
    }
    .charttext .apexcharts-datalabel-label {
      font-weight: 500 !important;
      font-size: 20.3px !important;
      line-height: 12.46px !important;
      color: #464646 !important;
    }
    .alert-chart-count {
      font-weight: 700;
      font-size: 18.44px;
      line-height: 28.26px;
      color: #464646;
    }
    .alert-chart-name {
      font-weight: 500;
      font-size: 12.45px;
      line-height: 12.65px;
      color: #464646;
    }
    .alert-progress {
      width: 56.23px;
      height: 5.97px;
    }
    .alert-progress-critical {
      border-radius: 37.32px 0px 0px 37.32px;
      background-color: #ff8181;
    }
    .alert-progress-warning {
      border-radius: 0px;
      background-color: #ff974c;
    }
    .alert-progress-problem {
      border-radius: 0px 37.32px 37.23px 0px;
      background-color: #6c83ff;
    }
  }
}
.info-card-cls {
  min-width: 524px;
  width: 100%;
  height: 685px;
  @media (max-width: 1441px) {
    min-width: 400px;
  }
  .info-header-cls span:nth-of-type(1) {
    font-size: 23px;
    font-weight: 500;
    line-height: 23px;
    margin-top: 5px;
    color: #3d4854;
  }
  .info-div {
    padding: 13px 10px 9px 20px;
    .info-name-cls {
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
      color: #5d6670;
      width: 50%;
      @media (max-width: 1441px) {
        font-size: 14px;
        width: 35%;
      }
    }
    .info-desc-cls {
      font-size: 13px;
      line-height: 15.6px;
      font-weight: 400;
      color: #5d6670;
      width: 46%;
    }
    .info-logo-cls {
      margin-top: -6px;
    }
    .info-desc-div {
      display: contents;
    }
    &:hover {
      background-color: #e6f1fe;
    }
    &:active {
      background-color: #e6f1fe;
    }
    .customer-service-cls {
      width: 69px;
      height: 28px;
      border-radius: 5px;
      font-size: 13px;
      font-weight: 400;
      line-height: 15.6px;
      text-align: center;
      margin-right: 12px;
      text-transform: capitalize;
      margin-bottom: 6px;
      border: 1px solid #d9d9d9;
      color: #5d6670 !important;
    }
    .active {
      border: 1px solid #0da300 !important;
    }
    .most-used-app-cls {
      width: 150px;
      height: 28px;
      border-radius: 5px;
      font-size: 13px;
      font-weight: 400;
      line-height: 15.6px;
      margin-right: 12px;
      text-transform: capitalize;
      margin-bottom: 6px;
      border: 1px solid #d9d9d9;
      color: #5d6670 !important;
      padding: 6px 10px 0px 13px;
    }
    .progressbar-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 19.09px;
      color: #ff8181;
    }
  }
  .block {
    height: 60px;
    border-radius: 10px;
    background: #ffff;
    padding: 18px 20px;
    display: grid;
    grid-template-columns: 100px 1fr 100px;
    .icon-cls {
      background: #e7e8ea;
      height: 34px;
      width: 34px;
      border-radius: 4px;
      padding: 7px;
    }
  }
  .active-activity {
    background: #e6f1fe;
  }
}
.iconContainer {
  position: absolute;
  right: 29px;
  bottom: 7px;
}
.customer-div {
  padding-top: 25px !important;
}
.no-data-found {
  font-size: 19px;
  color: #464646;
}
.overview-table-height {
  // height: 553px;
  overflow-x: auto;
}
.system-overview-cls {
  height: 685px;
  background: #ffffff;
  border-radius: 10px;
  .system-overview-label-cls {
    font-size: 23px;
    font-weight: 500;
    line-height: 23px;
    color: #3d4854;
    padding: 25px 0px 0px 20px;
  }
  .overview-table {
    font-size: 15px;
    font-weight: 700;
    line-height: 13px;
    color: #3d4854;
    height: 41px;
    padding: 14px 22px 14px 22px;
  }
  .trends-line-div-cls {
    width: 15%;
    min-width: 140px;
  }
  .other-div-cls {
    width: 18.33%;
    // width: 11.66%; old width
    min-width: 100px;
  }
  .trends-div-cls {
    width: 45%;
    // width: 25%; old width
    min-width: 310px;
  }
  .indicator-div-cls {
    width: 25%;
    min-width: 190px;
  }
  .overview-details-div {
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: #e7e8ea;
    height: 61px;
    padding: 12px 22px;
    // width: fit-content;
    .icon-cls {
      background: #e7e8ea;
      height: 34px;
      width: 34px;
      padding: 8px;
      border-radius: 4px;
    }
  }
  .table-title {
    font-size: 16px;
    line-height: 23px;
    font-weight: 500;
    color: #5d6670;
    @media (max-width: 1441px) {
      font-size: 14px;
    }
  }
  .table-desc {
    font-size: 13px;
    font-weight: 400;
    list-style: 15.6px;
    color: #5d6670;
  }
  .other-detail-text {
    font-size: 14px;
    font-weight: 500;
    list-style: 16.94px;
    color: #455d79;
  }
}
.indicator-div-cls {
  .indicator-progress {
    width: 34.5px;
    height: 8px;
  }
  .indicator-progress-critical,
  .indicator-progress-critical-last {
    background: #f27a7a26;
  }
  .indicator-progress-critical {
    border-radius: 50px 0px 0px 50px;
  }
  .indicator-progress-critical-last {
    border-radius: 0px 50px 50px 0px;
  }
  .indicator-progress-warning,
  .indicator-progress-warning-last {
    background: #f1ad2a26;
  }
  .indicator-progress-success {
    width: 40px;
    height: 8px;
    background: #01be4f26;
  }
  .active-critical {
    background: #f27a7a;
    box-shadow: 0px 4px 4px 0px #f27a7a70;
  }
  .active-critical-last {
    background: #f27a7a;
    box-shadow: 0px 4px 4px 0px #f27a7a70;
  }
  .active-warning {
    background: #f1ad2a;
    box-shadow: 0px 4px 4px 0px #f1ad2a70;
  }
  .active-warning-last {
    background: #f1ad2a;
    box-shadow: 0px 4px 4px 0px #f1ad2a70;
  }
  .active-success {
    background: #01be4f;
    box-shadow: 0px 4px 4px 0px #01be4f70;
  }
}
.note-height-cls {
  height: 300px;
}
.note-area-cls {
  border-radius: 4px !important;
  height: 83px;
  max-height: 120px;
  border: 1px solid #90969d;
}
.notes-list-cls {
  height: 64px;
  border-radius: 10px;
  background: #f9f9f9;
  .notes-title-cls {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    color: #0d1a29;
    @media (max-width: 1441px) {
      font-size: 14px;
    }
  }
  .notes-desc-cls {
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 400;
    color: #0d1a29;
    @media (max-width: 1441px) {
      font-size: 12px;
    }
  }
  .notes-date-cls {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: #3d4854;
  }
  .notes-edit-btn {
    width: 76px;
    // background: #0070f0;
    height: 33px;
    border-radius: 10px;
    box-shadow:
      0px 3px 1px -2px rgb(0 0 0 / 0%),
      0px 2px 2px 0px rgb(0 0 0 / 0%),
      0px 1px 5px 0px rgb(0 0 0 / 0%);
    font-size: 13px;
    line-height: 15.6px;
    font-weight: 500;
    color: #ffffff;
  }
}

.ssidinput {
  border: 1px solid #d0d1d2;
  outline: none;
  padding: 7px 7px;
  border-radius: 3px;
  //max-width: 100px;
  width: 100%;
  overflow-x: hidden;
  font-size: 13px;
  line-height: 15.6px;
  font-weight: 400;
  color: #5d6670;
  margin-left: -11px;
  &:first-of-type {
    margin-right: 4px;
  }
  &:disabled {
    background: none;
    border: 1px solid transparent;
  }
}
.applybtn {
  font-family: "SFProDisplay-Regular", sans-serif;
  letter-spacing: 1px;
  background: #a3d696;
  color: #ffffff;
  outline: none;
  border-radius: 5px;
  border: 1px solid #a3d696;
  font-size: 12px;
  padding: 2px 4px;
  cursor: pointer;
}
.error {
  border-color: #eb5757;
}

.ssidinput {
  border: 1px solid #d0d1d2;
  outline: none;
  padding: 7px 7px;
  border-radius: 3px;
  //max-width: 100px;
  width: 100%;
  overflow-x: hidden;
  font-size: 13px;
  line-height: 15.6px;
  font-weight: 400;
  color: #5d6670;
  margin-left: -11px;
  &:first-of-type {
    margin-right: 4px;
  }
  &:disabled {
    background: none;
    border: 1px solid transparent;
  }
}
.applybtn {
  font-family: "SFProDisplay-Regular", sans-serif;
  letter-spacing: 1px;
  background: #a3d696;
  color: #ffffff;
  outline: none;
  border-radius: 5px;
  border: 1px solid #a3d696;
  font-size: 12px;
  padding: 2px 4px;
  cursor: pointer;
}
.error {
  border-color: #eb5757;
}

.logs_antd {
  height: 500px;
  overflow-x: auto;
  word-wrap: initial !important;
}
.logs-header-cls {
  font-size: 15px;
  font-weight: bolder;
}
.log-details-cls {
  background: #95949429;
  border-radius: 6px;
  height: auto;
}
.pre-text-cls {
  white-space: pre-line;
}
.telemetry-chart-cls {
  height: 570px;
}
.logs_antd {
  height: 500px;
  overflow-x: auto;
}
.logs-header-cls {
  font-size: 15px;
  font-weight: bolder;
}
.log-details-cls {
  background: #95949429;
  border-radius: 6px;
  height: auto;
}
.pre-text-cls {
  white-space: pre-line;
}

.submit-chart-btn {
  width: 120px;
  height: 40px;
  letter-spacing: 1px;
  background: #a3d696;
  color: #ffffff;
  outline: none;
  border-radius: 5px;
  border: 1px solid #a3d696;
  font-size: 16px;
  padding: 2px 4px;
}
.save_pin_btn {
  background-color: #01be4f;
  font-size: 19px;
  color: white;
  width: 100px;
  height: 45px;
  border-radius: 10px;
}
.cancel_pin_btn {
  background-color: white;
  font-size: 19px;
  color: #01be4f;
  width: 100px;
  height: 45px;
  border-radius: 10px;
  border: 1px solid #01be4f;
}
.bg-height {
  height: 50px !important;
}
// New Css End

// Left Side ClS Start
.div-padding-top {
  padding-top: 15px;
}
.qoe-score-cls {
  width: 284px;
  height: 160px;
  padding: 40px 0px 0px 11px;
}
.overview-card,
.info-card-cls,
.activity-card {
  border-radius: 10px !important;
  box-shadow:
    0px 2px 1px -1px transparent,
    0px 1px 1px 0px #ffffff00,
    0px 1px 3px 0px transparent !important;
}

.overview-card span:nth-of-type(1) {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #5d6670;
  @media (max-width: 1441px) {
    font-size: 14px;
  }
}
.overview-card span:nth-of-type(2) {
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  color: #5d6670;
  padding-top: 5px;
}
.activities-label-cls {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #5d6670;
  @media (max-width: 1441px) {
    font-size: 14px;
  }
}
.activities-desc-cls {
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  color: #5d6670;
}
.activity-card {
  height: 645px !important;
}

.other-card-css {
  height: 100px;
}
.overview-info-cls {
  padding: 31px 30px 0px 21px !important;
}
.alerts-card-cls {
  width: 297px;
  height: 362px;
}
.pagination-css {
  padding: 0px 20px;
  .showing-text-cls {
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    color: #90969d;
  }
  .remove-bg-btn-cls {
    border: 0;
  }
}
.activity-logo-cls {
  height: 34px;
  width: 34px;
  padding: 3px 0px 0px 6px;
  background: #e7e8ea;
  border-radius: 4px;
}
.active-activity-cls {
  padding-top: 13px;
  height: 60px;
  background: #e6f1fe;
  border-radius: 10px;
}
.active-activity-details-cls {
  padding-top: 13px;
  height: 60px;
  background: #ffffff;
}
.pagination .active {
  --bs-pagination-active-bg: #e7e8ea !important;
}
.cls-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  --bs-pagination-active-border-color: #e7e8ea !important;
}
.fixed-pagination-container {
  .showing-pagination-text {
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    color: #90969d;
  }
  .disabled {
    --bs-pagination-disabled-bg: #ffffff;
  }
  .page-item {
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    --bs-pagination-color: #b4b8bd;
    --bs-pagination-focus-color: #0a58ca00;
    --bs-pagination-border-width: 0px;
    --bs-pagination-focus-box-shadow: 0 4px 30px rgba(13, 110, 253, 0.1);
  }
}
.notes-cls {
  height: 550px;
  background: #ffffff;
  border-radius: 10px;
  .notes-label-cls {
    font-size: 23px;
    font-weight: 500;
    line-height: 23px;
    color: #3d4854;
    padding: 25px 0px 0px 20px;
  }
  .text-danger {
    font-size: 0.75rem;
  }
}
.tags-cls {
  height: 100px;
  background: #ffffff;
  border-radius: 10px;
  .tags-label-cls {
    font-size: 23px;
    font-weight: 500;
    line-height: 23px;
    color: #3d4854;
    padding: 25px 0px 0px 20px;
  }
}
.middle_parts {
  width: 57.3%;
}
.right_parts {
  width: 28.75%;
}
.customers_wrapper {
  .custom_main_wrapper {
    background: #ffffff;
    border: 1px solid #eff2fc;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9;
    .customer_header_wrapper {
      height: 81px;
      padding: 19px 20px 0px;
      @media (max-width: 1400px) {
        max-width: 1300px;
      }
      .MuiTab-root {
        width: fit-content !important;
      }
    }
  }
}
.provisionLink {
  font-size: 16px;
  background: transparent;
  text-decoration: underline;
  font-weight: 500;
}

.ant-picker-range {
  height: 50px;
}
.ant-picker-focused {
  border-color: #c4c4c4 !important;
  box-shadow: 0 0 0 2px rgb(171 178 184 / 13%) !important;
}

.ant-picker-range .ant-picker-active-bar {
  background: #b6b4b4 !important;
}

.exitbutton {
  padding: 8px 64px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  // background-color: #6581fa;
  border: none;
}

.active-filter,
.inactive-filter {
  background: #ffffff;
  border-radius: 10px;
  font-size: 14px;
  margin: 0 8px;
  padding: 8px 16px;
}
.hitron-active-filter {
  background: #004c97 !important;
  box-shadow:
    1.5px 1.5px 3px 0 rgba(174, 174, 192, 0.4),
    -1px -1px 3px 0 #fff;
  color: #fff !important;
  padding: 8px 16px;
  border-radius: 10px;
}
.customer-active-filter {
  background: #ff974c !important;
  box-shadow:
    1.5px 1.5px 3px 0 rgba(174, 174, 192, 0.4),
    -1px -1px 3px 0 #fff;
  color: #fff !important;
  padding: 8px 16px;
  border-radius: 10px;
}
.active-filter {
  background: #a3d696 !important;
  box-shadow:
    1.5px 1.5px 3px 0 rgba(174, 174, 192, 0.4),
    -1px -1px 3px 0 #fff;
  color: #fff !important;
  padding: 8px 16px;
}
.entpnt-active-filter {
  background: #487a7b !important;
  box-shadow:
    1.5px 1.5px 3px 0 rgba(174, 174, 192, 0.4),
    -1px -1px 3px 0 #fff;
  color: #fff !important;
  padding: 8px 16px;
  border-radius: 10px;
}
.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
}
@media (max-width: 1867px) {
  .middle_parts {
    width: 52vw;
  }
}
@media (max-width: 1767px) {
  .right_parts {
    .info-card-cls {
      min-width: 480px;
    }
  }
  .overview-details-div {
    width: 100%;
  }
  .customers_wrapper {
    .tab-label {
      margin-left: 6px;
      margin-right: 6px;
    }
    .customer_header_wrapper {
      height: 76px;
      .MuiTab-root {
        width: fit-content !important;
      }
      .MuiButtonBase-root,
      .MuiTab-root {
        padding: 0px 10px !important;
        // padding-top: 0px !important;
      }
    }
  }
}
@media (max-width: 1450px) {
  .middle_parts {
    width: 43vw;
  }
  .overview-details-div {
    width: fit-content;
  }
}
@media (max-width: 1367px) {
  .right_parts {
    .info-card-cls {
      min-width: 430px;
    }
  }
  .middle_parts {
    width: 42vw;
  }
  .overview-details-div {
    width: fit-content;
  }
}
@media (max-width: 1290px) {
  .middle_parts {
    width: 40vw;
  }
}
.series-cls {
  color: #a3d696;
}
.load_more_btn {
  width: 130px;
  height: 36px;
  background: #ffffff;
  color: #0070f0;
  display: flex;
  align-items: center;
}
.graph-loader-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f7f8fe;
}
