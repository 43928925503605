.security_score {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  // max-width: 460px;
  padding: 26px 27px 42px 28px;

  .title {
    font-family: SFProDisplay-Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #282828;
  }

  .security_score_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 55px 0;
    .security_score_inner_block{
      font-size: 63px;
      margin-top: 16px;
      .security_score_loading{
        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
        width: 12rem;
        border-radius: 25px;
        background-color:  rgb(226 232 240);;
      }
    } 
  }

  .progress_bar {
    padding-top: 56px;
    padding-bottom: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .alert_row {
    display: inline-flex;
    align-items: center;
    padding: 8px 8px 8px 18px;
    width: 100%;
    margin-bottom: 5px;

    background: #ffffff;
    border-bottom: 2px solid #e8e8e8;

    .alert_row_inner{
      margin-left: 15px;
      font-size: 24px;
      display: flex;
      flex-direction: column;
      .device_name{
        color: #282828;
        font-size: 16px;
      }
      .sig_name{
        font-size: 14px;
        color: #6B7A9F;
      }
    }

    .score {
      font-family: SFProDisplay-Bold, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: right;
      margin-left: auto;
    }
  }
}
