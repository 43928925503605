.device-detail-wrapper {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 33px;
  flex: 1 1 0%;
  width: 100%;
  h1{
    font-size: inherit;
    font-weight: inherit !important;
  }
  .device-info-table {
    min-width: 600px;
    border-spacing: 0;
    border: none;

    color: #282828;
    overflow: hidden;

    tbody {
      border: none;
      color: #282828 !important;

      tr {
        background: #ffffff;
        border-radius: 10px;
        margin-bottom: 10px;

        td {
          border: none;
          padding: 16px 16px;
          text-align: left;
          vertical-align: center;
          @apply font-sf-light;
          input {
            border: 2px solid #e5e7eb;
            border-radius: 10px;
            padding: 8px;
            width: 100%;
            cursor: not-allowed;
            background-color: rgb(229 231 235);
          }
          label {
            position: relative;
            display: inline-flex;
            align-items: center;
            margin-right: 20px;
          }
        }

        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }

    @apply font-sf-bold;
  }
}
