.security_score {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  // max-width: 460px;
  padding: 26px 27px 42px 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-family: SFProDisplay-Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #282828;
  }

  .security_score_inner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 65px 0;
    margin: 10px 0 auto;
    .innerTitle{
      font-size: 63px;
      font-family: SFProDisplay-Bold, sans-serif !important;
    }
  }

  .progress_bar {
    padding-top: 56px;
    padding-bottom: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .alert_row {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 19px 22px 22px 26px;
    width: 100%;
    margin-bottom: 5px;

    background: #ffffff;
    border: 2px solid #f7f8fe;
    border-radius: 10px;

    .score {
      font-family: SFProDisplay-Bold, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: right;
    }
  }
}
