@import './HeaderSection/header-section.scss';
@import './Breadcrumbs/breadcrumbs.scss';
@import './RightSideBar/right-sidebar.scss';
@import './SearchBar/search-bar.scss';
@import './DropDown/drop-down.scss';
@import './Logout/log_out.scss';

.anmated-layout-wrapper {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: #f7f8fe;
  // min-width: 1490px;
  // width: max-content;
  // box-sizing: border-box;
}
