.footer {
  background: white;
  padding-right: 84px;
  padding-left: 34px;
  padding-bottom: 40px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.info_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  font-family: SFProDisplay-SemiBold, sans-serif;
  font-size: 14px;
  line-height: 17px;
  max-width: 599px;
  margin-left: auto;
}
.bad_connection_row {
  font-family: SFProDisplay-SemiBold, sans-serif;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}

.inline_flex_gap_16 {
  display: inline-flex;
  align-items: center;
  gap: 16px;
}

.inline_flex_gap_21 {
  display: inline-flex;
  align-items: center;
  gap: 21px;
}

.inline_flex_gap_34 {
  display: inline-flex;
  align-items: center;
  gap: 21px;
}

.inline_flex_gap_10 {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
