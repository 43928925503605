.autocomplete {
  // max-width: 858px;
  font-family: SFProDisplay-Regular, sans-serif;
  font-size: 20px;

  .input_container {
    position: relative;
    margin: 0 25px 0 20px;
    .search_glass {
      position: absolute;
      top: 16px;
      left: 20px;
    }

    // max-width: 858px;
  }

  .input {
    width: 450px;
    font-size: 20px;
    border-radius: 15px;
    border: 2px solid #eff2fc;
    outline: none;
    padding: 13px 36px 13px 60px;
    background: #ffffff;
    box-sizing: border-box;
    height: 60px;
    @media (max-width: "1500px") {
      width: 250px;
    }
    &:focus {
      background: #f9f9f9;
      border: 2px solid #eff2fc;
    }
    &:active {
      background: #f9f9f9;
      // border: none;
      outline: none;
    }
  }

  .options {
    position: absolute;
    top: 97%;
    right: 0px;
    width: 450px;
    max-height: 270px;
    overflow-y: auto;
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    //border: 1px solid #ccc;
    overflow-x: hidden;
    background-color: #f9f9f9;
    color: #282828;
    z-index: 1000;

    /* For Firefox */
    scrollbar-width: none;
    -ms-overflow-style: none;

    /* For Webkit browsers */
    &::-webkit-scrollbar {
      display: block;
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #eff2fc;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ababab;
      border-radius: 8px;
      border-right: none;
      border-left: none;
    }

    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px;
    }

    &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
    }
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;

    border: 2px solid #eff2fc;
    border-top: none;

    .option {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      width: 450px;
      box-sizing: border-box;
      border-top: 2px solid #eff2fc;
      border-bottom: 2px solid #eff2fc;
      text-decoration: none;
      color: #282828;
      &:last-of-type {
        border-bottom: none;
      }
      &:first-of-type {
        border-top: none;
      }

      .email_with_icon {
        display: flex;
        align-items: center;
        min-width: 220px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        svg {
          width: 24;
          color: #6b7a9f;
          path {
            strokeWidth: 2px;
          }
        }

        span {
          margin-left: 20px;
          font-size: 16px;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .gw {
        margin-left: 20px;
        width: 120px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 16px;
        &:hover {
          text-decoration: underline;
        }

        mark {
          background: none;
          font-weight: bold;
          font-size: 16px;
        }
      }

      .icon {
        color: #6b7a9f;
      }

      &:hover {
        background-color: #f4f5fa;
        cursor: pointer;
      }

      .user_pill {
        font-size: 14px;
        padding: 8px 13px;
        border-radius: 10px;
        border: 1px solid #ababab;
        color: #ababab;
      }

      .alert_pill {
        font-size: 14px;
        padding: 8px 13px;
        border-radius: 10px;
        background: #01be4f;
        color: #ffffff;
        border: none;
      }
    }
  }
}

.left_option_wrapper {
  display: flex;
  align-items: center;
}

.mark {
  mark {
    background: none;
    font-weight: bold;
  }
}

@media (max-width: 1370px) {
  .autocomplete {
    .input_container {
      .search_glass {
        left: 20px;
      }
    }
    .input {
      padding: 15px 36px 15px 58px;
      font-size: 16px;
    }
    .options {
      .option {
        padding: 20px 16px;
        font-size: 16px;
        .email_with_icon {
          span {
            margin-left: 12px;
          }
          min-width: 170px;
        }
        .gw {
          font-size: 14px;
          width: 100px;
        }
        .user_pill {
          width: 78px;
        }
      }
    }
  }
}
