.qoe-wrapper {
  .qoe_table {
    background: #ffffff;
    margin-bottom: 24px;
    border-radius: 10px;
    padding: 20px;
  }
  .justify-between {
    justify-content: space-between;
  }
  .qoe-chart {
    // height: calc(100vh - 140px);
    // overflow-y: auto;
    float: right;
    width: calc(100vw - 355px);
    scrollbar-width: none;
    -ms-overflow-style: none;

    /* For Webkit browsers */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    &-wrapper {
      margin-bottom: 24px;
      padding: 15px;
      border-radius: 8px;
      background-color: #ffffff;
    }
  }
  .hitron-text {
    color: #dc4405;
  }
  .qoe-device-wrapper {
    float: left;
    // height: calc(100vh - 140px);
    // overflow-y: auto;
    max-width: 350px;
    min-width: 250px;
    overflow-x: hidden;
    // scrollbar-width: none;
    // -ms-overflow-style: none;

    /* For Webkit browsers */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .device-wrapper {
      // display: flex;
      // min-height: 140px;
      min-height: 100px;
      max-width: 280px;
      min-width: 200px;
      width: 100%;
      padding: 10px;
      background-color: #ffffff;
      border-radius: 8px;
      margin-bottom: 24px;
      span {
        text-align: start;
      }
    }
    .device-image {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      width: 80px;
      img {
        //   height: 80px;
        // width: 80px;
        height: 100%;
        max-width: none;
      }
    }
    .active-border {
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
      // border: 2px solid #a3d696;
    }
    .hitron-active-border {
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
      // border: 2px solid #00cc66;
    }
    .in-active-border {
      border: 2px solid #a3d69666;
    }
  }
  .deviceDetsils {
    padding: 10px 0;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 21.84px !important;
  }
  .deviceData {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 19.09px !important;
  }
}
