.device-tab {
  input {
    margin-right: 5px;
    padding: 4px 5px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
  }
  .btn-primary {
    padding: 2px 16px 5px 16px;
  }
  .ant-picker-range {
    height: auto;
  }
  .submit-btn {
    background-color: #01be4f;
    color: white;
    border: 1px solid #01be4f;
    padding: 4px 8px;
    &:hover {
      color: #01be4f;
      border: 1px solid #01be4f;
    }
  }
  .submit-btn-hitron {
    background-color: #004c97;
    color: white;
    border: 1px solid #004c97;
    padding: 4px 8px;
    &:hover {
      color: #004c97;
      border: 1px solid #004c97;
    }
  }
  .download-btn {
    font-size: 11px;
    padding: 6px 2px;
  }
}
