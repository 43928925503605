.supervision-inner-block {
  border-radius: 10px;
}
.table-main-wrapper {
  padding: 0px 20px;

  .table-inner-wrapper {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: 0 49px;
    padding: 16px 24px;
    margin-bottom: 16px;
  }
  table {
    overflow-x: auto;
  }
  table tbody tr td,
  table thead tr td {
    text-align: center;
  }
  table tbody tr td svg {
    display: inline;
  }

  table tr td:nth-child(1) {
    min-width: 5%;
  }
  table tr td:nth-child(2) {
    min-width: 5%;
  }
  table tr td:nth-child(3) {
    min-width: 10%;
  }
  table tr td:nth-child(4) {
    min-width: 30%;
  }
  table tr td:nth-child(5) {
    min-width: 30%;
  }
  table tr td:nth-child(6) {
    min-width: 20%;
  }
}
